import { notification } from 'ant-design-vue';

const placement = 'bottomRight';
export function error(text) {
  notification.error({
    message: 'Ошибка',
    description: text,
    placement
  });
}

export function info(text) {
  notification.info({
    message: 'Информация',
    description: text,
    placement
  });
}

export function warning(text) {
  notification.warning({
    message: 'Предупреждеие',
    description: text,
    placement
  });
}

export function success(text) {
  notification.success({
    message: 'Успех',
    description: text,
    placement
  });
}