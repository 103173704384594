import { Modal } from 'ant-design-vue';

export function showDeleteConfirm(
  okFunc, 
  text = "Вы действительно хотите удалить эту запись?",
  cancelFunc = () => {}
) {
  Modal.confirm({
    title: text,
    okType: 'danger',
    onOk: okFunc,
    onCancel: cancelFunc,
  });
}

export function showConfirm(options) {
  Modal.confirm({
    title: options.text || "Вы уверены?",
    okType: 'danger',
    onOk: options.ok,
    onCancel: options.cancel,
  });
}