<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" @click="showCreationModal">
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length != 1"
        @click="showEditModal(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length == 0"
        @click="deleteEntity"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length == 0"
        @click="showPasswordModal"
      >
        <template #icon><i class="fa fa-lock" /></template>
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(100%)', y: '100%' }"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
    >
      <template #checkbox="{ record, column }">
        <span>
          <a-switch disabled v-model:checked="record[column.dataIndex]" />
        </span>
      </template>
    </a-table>
    
    <a-modal
      title="Пользователь"
      v-model:visible="modalVisible"
      :confirm-loading="modalSaving"
      @ok="saveForm"
    >
      <a-form 
        ref="form" 
        :model="formState"
        :rules="formRules"
      >
        <a-form-item name="login" label="Логин">
          <a-input v-model:value="formState.login" />
        </a-form-item>
        <a-form-item v-if="isNewItem" name="password" label="Пароль">
          <a-input v-model:value="formState.password" type="password" />
        </a-form-item>
        <a-form-item name="name" label="Имя">
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item name="patronimyc" label="Отчество">
          <a-input v-model:value="formState.patronimyc" />
        </a-form-item>
        <a-form-item name="surname" label="Фамилия">
          <a-input v-model:value="formState.surname" />
        </a-form-item>
        <a-form-item name="userRole" label="Роль">
          <a-select
            v-model:value="formState.userRole"
            :options="availableRoles"
          />
        </a-form-item>
        <a-form-item name="isBlocked" label="Заблокирован">
          <a-switch v-model:checked="formState.isBlocked" />
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      title="Смена пароля"
      v-model:visible="passwordModalVisible"
      :confirm-loading="passwordModalSaving"
      @ok="changePassword"
    >
      <a-form
        ref="pwdForm"
        :model="passwordModalFormState"
        :rules="passwordModalFormRules"
      >
        <a-form-item name="value" label="Новый пароль">
          <a-input v-model:value="passwordModalFormState.value" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import axios from "axios";
import { error, success } from "@/helpers/notifications";
import { showDeleteConfirm } from "@/helpers/modals";
import { required, email } from "@/helpers/validation";

const baseUrl = "UserManage";

export default {
  name: "UserManager",
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID" },
        { dataIndex: "login", title: "Логин" },
        { dataIndex: "name", title: "Имя" },
        { dataIndex: "patronimyc", title: "Отчество" },
        { dataIndex: "surname", title: "Фамилия" },
        { dataIndex: "userRole.title", title: "Роль" },
        { dataIndex: "isBlocked", title: "Заблокирован", slots: {customRender: 'checkbox'} },
      ],
      gridData: [],
      selectedItem: [],
      selectedRowKeys: [],
      selectedRows: [],

      modalVisible: false,
      modalSaving: false,
      formState: {},
      formRules: {
        login: [required("Логин"), email()],
        password: [required("Пароль")],
      },
      availableRoles: [],

      passwordModalVisible: false,
      passwordModalSaving: false,
      passwordModalFormState: {
        value: null
      },
      passwordModalFormRules: {
        value: [required("Пароль")]
      }
    };
  },
  computed: {
    isNewItem() {
      return !this.formState.id;
    }
  },
  methods: {
    async reload() {
      this.gridData = (await axios.get(baseUrl)).data;
    },
    rowClick(record) {
      return {
        onClick: () => {
          this.selectedItem = record;
          this.showEditModal([record]);
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    resetModal() {
      this.formState = {};
      this.modalSaving = false;
    },
    showModal() {
      this.modalVisible = true;
    },
    showCreationModal() {
      this.resetModal();
      this.showModal();
    },
    async showEditModal(selectedRows) {
      this.formState = { ...selectedRows[0] };
      if (this.formState.userRole) {
        this.formState.userRole = this.formState.userRole.id;
      }

      this.showModal();
    },
    async saveForm() {
      await this.$refs.form.validate();

      this.modalSaving = true;
      await this.$nextTick();

      let model = { ...this.formState };
      model.userRole = { id: model.userRole };
      
      try {
        if (this.isNewItem)
          await axios.post(baseUrl, model);
        else 
          await axios.patch(baseUrl, model);
        success("Сохранено");
      
        this.modalVisible = false;
        this.resetModal();
        await this.reload();
      } catch(e) {
        this.modalSaving = false;
        error("Ошибка при сохранении");
      }
    },
    async deleteEntity() {
      showDeleteConfirm(async () => {
        try {
          await axios.delete(baseUrl, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            data: this.selectedRowKeys,
          });
          success("Удалено");
        } catch(e) {
          error("Ошибка при удалении");
        }
        this.selectedRowKeys = [];
        await this.reload();
      });
    },
    
    showPasswordModal() {
      this.passwordModalVisible = true;
    },
    async changePassword() {
      await this.$refs.pwdForm.validate();

      this.passwordModalSaving = true;
      await this.$nextTick();
      try {
        await axios.post(baseUrl + "/password?id=" + this.selectedRowKeys[0], { password: this.passwordModalFormState.value });
        success("Пароль изменен");
      } catch(e) {
        error("Ошибка при изменении пароля");
      }
      this.passwordModalSaving = false;
      this.passwordModalVisible = false;
      this.passwordModalFormState.value = null;
    }
  },
  async created() {
    await this.reload();

    let roles = (await axios.get(baseUrl + "/roles")).data;
    this.availableRoles = roles.map(x => ({ label: x.title, value: x.id }));
  },
};
</script>